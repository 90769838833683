/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Throttle function
        function throttle(fn, threshhold, scope) {
          threshhold = threshhold ? threshhold : 250;
          var last,
              deferTimer;
          return function () {
            var context = scope || this;

            var now = +new Date(),
                args = arguments;
            if (last && now < last + threshhold) {
              // hold on to it
              clearTimeout(deferTimer);
              deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
              }, threshhold);
            } else {
              last = now;
              fn.apply(context, args);
            }
          };
        }

        var $menuButton = $('.navbar-toggle'),
            $menu = $('#navbar'),
            $body = $('body');
        $menu.addClass('js');
        $menuButton.on('click', function() {
          $menu.toggleClass('shown');
          $menuButton.toggleClass('collapsed');
        });
        
        function onWindowScroll() {
          if($body.scrollTop() > 0) {
            $menu.addClass('scrolled');
          } else {
            $menu.removeClass('scrolled');
          }
        }

        onWindowScroll();
        $( window ).scroll(throttle(onWindowScroll, 500));

        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#pl-carousel').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          directionNav: false,
          slideshow: false,
          itemWidth: 150,
          asNavFor: '#pl-slider'
        });
        $('#pl-slider').flexslider({
          animation: "slide",
          controlNav: false,
          animationLoop: false,
          directionNav: false,
          slideshow: false,
          sync: '#pl-carousel'
        });

        function initGoogleMap() {
          var pediatrieLutry = {
            lat: 46.502526,
            lng: 6.683372
          };
          var pediatrieLutryCenter = {
            lat: 46.50361699439785,
            lng: 6.683371999999981
          };
          var map = new google.maps.Map(document.getElementById('pl-gmap'), {
            center: pediatrieLutryCenter,
            zoom: 16
          });

          var plMarker = new google.maps.Marker({
            position: pediatrieLutry,
            map: map,
            title: 'Cabinet de Pédiatrie de Lutry',
            label: {
              text: '+',
              color: '#fff',
              fontSize: '18px',
              fontWeight: '600',
              fontFamily: 'Roboto, Verdana, Geneva, sans-serif'
            },
            icon: {
              path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
              fillColor: '#ED1E79',
              fillOpacity: 1,
              scale: 0.7,
              strokeColor: '',
              strokeWeight: 0,
              labelOrigin: new google.maps.Point(0, -29),
            }
          });

          parkingLutry = {
            lat: 46.503168,
            lng: 6.685583
          };

          var plParkingMarker = new google.maps.Marker({
            position: parkingLutry,
            map: map,
            title: 'Parking de Lutry',
            label: {
              text: 'P',
              color: '#fff',
              fontSize: '14px',
              fontFamily: 'Roboto, Verdana, Geneva, sans-serif'
            },
            icon: {
              path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
              fillColor: '#3FA9F5',
              fillOpacity: 1,
              scale: 0.6,
              strokeColor: '',
              strokeWeight: 0,
              labelOrigin: new google.maps.Point(2, -29),
            }
          });

          var plContent = '<div id="pl-gmap-iw">' + $('#pl-footer .vcard').html() + '</div>';
          var infoWindow = new google.maps.InfoWindow({
            content: plContent
          });

          plMarker.addListener('click', function() {
            infoWindow.open(map, plMarker);
          });
        }
        initGoogleMap();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact page.
    'page_template_page_contact': {
      init: function() {
        // JavaScript to be fired on the about us page

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
